<template>
    <div class="app">
        <Header/>
        <!-- Content -->
        <div class="container" style="margin-top: 120px;">
            <div class="row">
                <div class="col">
                    <h1 style="color: #FE9800; font-weight: bold;">Insight</h1>
                    <hr style="border: 2px solid #0077E5;margin-left: -120px;width: 250px;">
                </div>
            </div>
            <div class="row my-5">
                <div class="col-12" >
                   <router-link :to="{ name: 'InsightDetail', params: { article_name: insightF.article_name}}">
                    <img :src="'https://makutacomm-api.makutapro.id/storage/images/article/'+insightF.article_img" class="img-fluid" alt="" width="100%">
                    </router-link>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <span style="font-weight: 600;font-size: 28px;color: #535353">
                        {{insightF.article_name}}
                    </span>
                    <span class="text-insight mt-2" v-html="insightF.article_content+' ....'"></span>
                    <router-link :to="{ name: 'InsightDetail', params: { article_name: insightF.article_name}}">
                        <button type="button" class="btn btn-sm btn-outline-primary">View More</button>
                    </router-link>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <hr style="border: 1px solid #0077E5;">
                </div>
            </div>

            <div class="row mt-5">
                <router-link v-for="insight in insights" :key="insight.index" :to="{ name: 'InsightDetail', params: { article_name: insight.article_name }}" class="col-12 col-md-6 col-lg-6 nav-link" >
                    <div class="card" style="border:none">
                        <img :src="'https://makutacomm-api.makutapro.id/storage/images/article/'+insight.article_img" class="img-fluid" alt="">
                        <div class="card-body">
                            <ul class="list-group" type="none">
                                <li>
                                    <span class="card-text text-insight">{{insight.article_name}}</span>
                                </li>
                                <li class="insight-child" style="list-style-type: none;" v-html="insight.article_content+' ....'">
                                </li>
                            </ul>
                            <router-link :to="{ name: 'InsightDetail', params: { article_name: insight.article_name }}" class="col-12 col-md-6 col-lg-6 nav-link">
                                <button type="button" class="btn btn-sm px-2 py-0 mt-2 btn-outline-primary"><small>View More</small></button>
                            </router-link>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
        <!-- Footer -->
        <footer-page/>
    </div>
</template>

<script>
import FooterPage from '../components/FooterPage.vue'
import Header from '../components/Header.vue'
export default {
  components: { FooterPage, Header },
    name: 'Insight',
    data(){
        return{
            insightF: [],
            insights:[]
        }
    },
    created() {
      this.$axios.get('https://makutacomm-adm.makutapro.id/api/insights')
      .then((response) => {
       console.log(response)
        this.insights = response.data.data
       })
      this.$axios.get('https://makutacomm-adm.makutapro.id/api/insightsFirst')
      .then((response) => {
       console.log(response)
        this.insightF = response.data
       })
    },
}
</script>

<style scoped>
.text-insight{
    color: #535353;
}
.insight-child {
    color: black;
    font-size: 13px;
}
</style>